import moment from "moment";
import { HttpLogin } from "../Http";
import axios from "axios";
export class LoginAPI {

  public static saveLogin(user: any) {
    var url = "/api/user/signin";
    var obj = JSON.stringify(user);
    const resultMethod = HttpLogin.axios().post(url, obj,
      {
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*"
        }
      })
      .then(res => {
        return res;
      }).catch((e: any) => {
        return e.response;
      });
    return resultMethod;

  }

  public static loginSecurity(input: any) {
    var obj = JSON.stringify(input);
    var url = "/api/user/verify";
    const resultMethod = HttpLogin.axios().post(url, obj,
      {
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*"
        }
      })
      .then(res => {
        return res;
      }).catch((e: any) => {
        return e.response;
      });
    return resultMethod;
  }

  public static forgetPassword(input: any) {

    var obj = JSON.stringify(input);
    var url = "api/user/forgot-password";
    const resultMethod = HttpLogin.axios().post(url, obj,
      {
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*"
        }
      })
      .then(res => {


        return res;
      }).catch((e: any) => {
        return e.response;
      });
    return resultMethod;
  }

  public static async ResetPassword(input: any) {
    const url = "/api/user/recreatePassword";
    const headers = {
      'Content-Type': 'application/json',
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Methods": "*",
      "Access-Control-Allow-Headers": "*"
    };

    try {
      const response = await axios.post(url, JSON.stringify(input), { headers });

      // Check for success status
      if (response.status >= 200 && response.status < 300) {
        return response.data;  // Return response data on success
      }
    } catch (error: any) {
      // Handle different error types
      if (error.response) {
        // Server responded with a status code out of the 2xx range
        console.error("Server Error:", error.response.data);
        return {
          success: false,
          message: error.response.data.message || 'Server error occurred',
          status: error.response.status
        };
      } else if (error.request) {
        // The request was made but no response was received
        console.error("No Response:", error.request);
        return {
          success: false,
          message: 'No response received from the server. Please try again.',
          status: null
        };
      } else {
        // Other errors like network or configuration issues
        console.error("Error in request setup:", error.message);
        return {
          success: false,
          message: 'Request failed due to network or configuration issues.',
          status: null
        };
      }
    }
  }

  // public static ResetPassword(input: any) {    
  //   var obj = JSON.stringify(input);
  //   var url = "/api/user/recreatePassword";  
  //   const resultMethod = HttpLogin.axios().post(url, obj,
  //     {
  //       headers: {
  //         'Content-Type': 'application/json',
  //         "Access-Control-Allow-Origin": "*",
  //         "Access-Control-Allow-Methods": "*",
  //         "Access-Control-Allow-Headers": "*"
  //       }
  //     })
  //     .then(res => { 
  //       return res;
  //     }).catch((e: any) => {     
  //       return e.response;
  //     });    
  //   return resultMethod;
  // }


  public static async verifyLogin(input: any) {
    var url = "/api/user/verify";
    var source = {
      secretKey: input.secretKey,
      jwtToken: input.jwtToken
    };
    var obj = JSON.stringify(source);
    var obJSon = JSON.parse(obj);
    const resultMethod = HttpLogin.axios().post(url, obJSon,
      {
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*"
        }
      })
      .then(res => {
        return res;
      }).catch((e: any) => {
        var response = {
          "message": "Login successful!"
        }
        return response;
      });
    return resultMethod;
  }


  public static verifyotp(input: any) {

    var obj = JSON.stringify(input);
    var url = "api/user/verify-otp";

    const resultMethod = HttpLogin.axios().post(url, obj,
      {
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*"
        }
      })
      .then(res => {

        return res;
      }).catch((e: any) => {
        return e.response;
      });
    return resultMethod;
  }

  public static resetSecretKey(input: any) {

    var url = "api/user/resetSecretKey";
    var source = {
      email: input.email,
      jwt: input.jwt
    };
    var obj = JSON.stringify(source);

    var obJSon = JSON.parse(obj);
    //  console.log("obj="+obj);

    const resultMethod = HttpLogin.axios().post(url, obJSon,
      {
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*"
        }
      })
      .then(res => {
        // console.log("Response in post" + res);

        return res;
      }).catch((e: any) => {
        // console.log("Error in post" + e);
        return e.response;
      });
    return resultMethod;
  }

  public static reCreatePassword(input: any) {

    var url = "/api/user/recreatePassword";

    var obj = JSON.stringify(input);
    // console.log("obj="+obj);

    const resultMethod = HttpLogin.axios().post(url, obj,
      {
        headers: {
          'Content-Type': 'application/json',
          "Access-Control-Allow-Origin": "*",
          "Access-Control-Allow-Methods": "*",
          "Access-Control-Allow-Headers": "*"
        }
      })
      .then(res => {
        // console.log("Response in post" + JSON.stringify(res));

        return res;
      }).catch((e: any) => {
        // console.log("Error in post" + e);
        return e.response;
      });
    return resultMethod;
  }

}

